//This returns a component which can be rendered in any product specific [[page.tsx]]
//to return the page template(Head, main, sections) along with the required react Components mentioned in model.json and
// load the CSS and JS libs required by static components from AEM.
import { ResponsiveGrid } from '@adobe/aem-react-editable-components';
import { ApolloProvider } from '@apollo/client';
import Head from 'next/head';
import { ApolloEnvVars, getDeployedEnvType, useNextMiApolloClient } from '@marriott/mi-apollo-client-utils';
import { Layout } from '../Layout';
import { respGridUtil } from '../../lib/helpers';

/* eslint-disable @typescript-eslint/no-explicit-any */
export function PageRendererComponent({
  model,
  pagePath,
  operationSignatures,
  isAuthorMode,
  USE_MOCK_MODEL,
  apolloEnvVars,
}: {
  model?: any;
  pagePath?: string;
  operationSignatures?: Array<any>;
  isAuthorMode?: boolean;
  USE_MOCK_MODEL?: boolean;
  apolloEnvVars?: ApolloEnvVars;
}) {
  const MiApolloClient = useNextMiApolloClient(
    operationSignatures || [],
    apolloEnvVars?.DEPLOYED_ENV_TYPE || getDeployedEnvType(),
    {},
    {},
    apolloEnvVars
  );

  const {
    metaNames,
    metaProperties,
    title,
    staticComponentClientLibsCSS,
    staticComponentClientLibsJS,
    gridCss,
    canonical,
    hreflangList,
  }: {
    metaNames: Array<any>;
    metaProperties: Array<any>;
    title: string;
    staticComponentClientLibsCSS: string;
    staticComponentClientLibsJS: string;
    gridCss: string;
    canonical: string;
    hreflangList: Array<any>;
  } = model;
  const respGridModel = respGridUtil(model?.cqItems?.root?.[':items']?.responsivegrid);
  const responsiveGridHeaderModel = model?.cqItems?.root?.[':items']?.['header-responsivegrid']
    ? respGridUtil(model?.cqItems?.root?.[':items']?.['header-responsivegrid'])
    : false;
  const responsiveGridFooterModel = model?.cqItems?.root?.[':items']?.['footer-responsivegrid']
    ? respGridUtil(model?.cqItems?.root?.[':items']?.['footer-responsivegrid'])
    : false;

  return (
    <ApolloProvider client={MiApolloClient}>
      <Layout
        metaNames={metaNames}
        metaProps={metaProperties}
        title={title}
        staticComponentClientLibsCSS={staticComponentClientLibsCSS}
        staticComponentClientLibsJS={staticComponentClientLibsJS}
        gridCss={gridCss}
        isAuthorMode={isAuthorMode}
        USE_MOCK_MODEL={USE_MOCK_MODEL}
        canonical={canonical}
        hreflangList={hreflangList}
      >
        <Head>
          <title>{model.title}</title>
        </Head>
        {responsiveGridHeaderModel && (
          <ResponsiveGrid
            {...responsiveGridHeaderModel}
            pagePath={pagePath}
            key={pagePath}
            itemPath="root/header-responsivegrid"
          />
        )}
        <section id="main-content">
          <ResponsiveGrid
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            pagePath={pagePath}
            itemPath="root/responsivegrid"
            key={pagePath}
            model={respGridModel}
          />
        </section>
        {responsiveGridFooterModel && (
          <ResponsiveGrid
            {...responsiveGridFooterModel}
            key={pagePath}
            pagePath={pagePath}
            itemPath="root/footer-responsivegrid"
          />
        )}
      </Layout>
    </ApolloProvider>
  );
}
