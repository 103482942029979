import Head from 'next/head';
import React, { ReactElement, useMemo } from 'react';

export const siteTitle = 'Marriott';

export function Layout({
  children,
  title,
  metaNames,
  metaProps,
  staticComponentClientLibsCSS,
  staticComponentClientLibsJS,
  gridCss,
  isAuthorMode,
  canonical = '',
  USE_MOCK_MODEL = false,
  hreflangList = [],
  mainID = '',
}: {
  children?: ReactElement | Array<ReactElement>;
  title?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  metaNames: Array<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  metaProps?: Array<any>;
  staticComponentClientLibsCSS?: string;
  staticComponentClientLibsJS?: string;
  gridCss?: string;
  isAuthorMode?: boolean;
  canonical?: string;
  USE_MOCK_MODEL?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  hreflangList?: Array<any>;
  mainID?: string;
}) {
  const generateClientLibsHost = useMemo(() => {
    if (String(process.env['IS_LOCAL_DEV']) === 'true' && !isAuthorMode && !USE_MOCK_MODEL) {
      return process.env['NEXT_PUBLIC_AEM_HOST'];
    } else {
      return '';
    }
  }, []);

  return (
    <>
      <Head>
        <meta charSet="UTF-8" />
        <link rel="icon" href="/favicon.ico" />
        <title>{title}</title>
        {metaNames?.map(metaName => (
          <meta key={metaName.name} name={metaName.name} content={metaName.value}></meta>
        ))}
        {metaProps?.map(metaProperty => (
          <meta key={metaProperty.name} property={metaProperty.name} content={metaProperty.value}></meta>
        ))}
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        {canonical && <link rel="canonical" href={canonical} />}
        <link rel="preload" href={`${generateClientLibsHost}${staticComponentClientLibsCSS}`} as="style" />
        <link rel="stylesheet" href={`${generateClientLibsHost}${staticComponentClientLibsCSS}`} />
        <link rel="stylesheet" href={`${generateClientLibsHost}${gridCss}`} />
        {hreflangList?.map((item, index) => (
          <link rel="alternate" href={item?.href} hrefLang={item?.hreflang} key={`href-lang-list-${index}`} />
        ))}
      </Head>
      <main id={mainID}>{children}</main>
      {staticComponentClientLibsJS && (
        <script src={`${generateClientLibsHost}${staticComponentClientLibsJS}`} defer></script>
      )}
    </>
  );
}
